import { Flyouts, Navigation } from "@iss/client-react-components";
import { useOktaAuth } from "@okta/okta-react";
import React, { useContext, useState } from "react";
import DataViewLayout from "../../console/dataview/layout";
import FilterView from "../../console/filterview/layout";
import HelpViewLayout from "../../console/helpview/layout";
// import { UserList } from "../../console/user-management/userlist";
import wealthminelogo from "../assets/wealthmineLogo.JPG";
import { StoreContext } from "../services/store";

function Header(props) {
  const { Menu, MenuItem, Triggered, ALIGNMENTS } = Flyouts;
  // const { authenticationInfo, attemptLogout } = useContext(StoreContext);
  const { oktaAuth } = useOktaAuth();
  const { userProfile } = useContext(StoreContext);

  const [navChildren, setNavChild] = useState({
    children: [
      {
        title: "Data View",
        component: DataViewLayout,
        active: true,
        allowedRoles: ["USER", "ADMIN"],
      },
      {
        title: "Filter",
        component: FilterView,
        active: false,
        allowedRoles: ["USER", "ADMIN"],
      },
      {
        title: "Help",
        component: HelpViewLayout,
        active: false,
        allowedRoles: ["USER", "ADMIN"],
      },
      // {
      //   title: "User Management",
      //   component: UserList,
      //   active: false,
      //   allowedRoles: ["ADMIN"],
      // },
    ],
  });

  function handleClick(link, currObject) {
    setNavChild((currState) => ({
      children: currState.children.map((element) =>
        element.title === currObject.title
          ? { ...element, active: true }
          : { ...element, active: false }
      ),
    }));
    props.onTabChange(currObject.component);
  }

  return (
    <header className="fixed-top">
      <div>
        <nav>
          <div>
            <div className="iss-main-header">
              <img
                className="iss-main-header-logo"
                title="Institutional Shareholder Services"
                alt="Institutional Shareholder Services"
                src={wealthminelogo}
              />
              <div className="logout-main">
                <Triggered
                  style={{ textTransform: "capitalize" }}
                  trigger={
                    <button className="iss-btn-outline-transparent dropdown-toggle">
                      {/* {authenticationInfo.firstName +
                        " " +
                        authenticationInfo.lastName} */}
                      {userProfile?.firstName + " " + userProfile?.lastName}
                      <i className="icon-chevron-down dropdown-icon" />
                    </button>
                  }
                  horizontalAlign={ALIGNMENTS.RIGHT}
                >
                  <Menu kind="light">
                    <MenuItem
                      // onClick={() => {
                      //   attemptLogout();
                      // }}
                      onClick={async () => {
                        await oktaAuth.signOut();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Triggered>
              </div>
            </div>
            <div className="custom-nav">
              <Navigation.NavBar title="2023 Estimates" className="custom-nav">
                {navChildren.children.map((child, index) => {
                  // if (child.allowedRoles.includes(authenticationInfo.role)) {
                  return (
                    <a
                      key={index}
                      // active={child.active}
                      onClick={() => handleClick(child.link, child)}
                    >
                      {child.title}
                    </a>
                  );
                  // } else {
                  //   return <div key={index}></div>;
                  // }
                })}
              </Navigation.NavBar>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
