import _ from "lodash";
import React, { Component, createContext } from "react";
import { getSum, keyList } from "../functions";
import { useOktaAuth } from "@okta/okta-react";

const sortOptions = {
  count: "Count",
  fCount: "fCount",
  cCount: "concent",
  wealth: "Wealth",
  fWealth: "fWealth",
  cWealth: "concentW",
  avgWealth: "AW",
  fAvgWealth: "fAW",
  age1h: "sum(AGE1h)/sum(Count)",
  age2h: "sum(AGE2h)/sum(Count)",
  age3h: "sum(AGE3h)/sum(Count)",
  age4h: "sum(AGE4h)/sum(Count)",
  age5h: "sum(AGE5h)/sum(Count)",
  age6h: "sum(AGE6h)/sum(Count)",
  age7h: "sum(AGE7h)/sum(Count)",
  fage1c: "sum(f1AGEc)/sum(fCount)",
  fage2c: "sum(f2AGEc)/sum(fCount)",
  fage3c: "sum(f3AGEc)/sum(fCount)",
  fage4c: "sum(f4AGEc)/sum(fCount)",
  fage5c: "sum(f5AGEc)/sum(fCount)",
  fage6c: "sum(f6AGEc)/sum(fCount)",
  fage7c: "sum(f7AGEc)/sum(fCount)",
  inc1h: "sum(INC1h)/sum(Count)",
  inc2h: "sum(INC2h)/sum(Count)",
  inc3h: "sum(INC3h)/sum(Count)",
  inc4h: "sum(INC4h)/sum(Count)",
  inc5h: "sum(INC5h)/sum(Count)",
  inc6h: "sum(INC6h)/sum(Count)",
  inc7h: "sum(INC7h)/sum(Count)",
  finc1c: "sum(f1INCc)/sum(fCount)",
  finc2c: "sum(f2INCc)/sum(fCount)",
  finc3c: "sum(f3INCc)/sum(fCount)",
  finc4c: "sum(f4INCc)/sum(fCount)",
  finc5c: "sum(f5INCc)/sum(fCount)",
  finc6c: "sum(f6INCc)/sum(fCount)",
  finc7c: "sum(f7INCc)/sum(fCount)",
  cfa1h: "sum(CFA1h)/sum(Count)",
  cfa2h: "sum(CFA2h)/sum(Count)",
  cfa3h: "sum(CFA3h)/sum(Count)",
  cfa4h: "sum(CFA4h)/sum(Count)",
  cfa5h: "sum(CFA5h)/sum(Count)",
  cfa6h: "sum(CFA6h)/sum(Count)",
  cfa7h: "sum(CFA7h)/sum(Count)",
  fcfa1c: "sum(f1CFAc)/sum(fCount)",
  fcfa2c: "sum(f2CFAc)/sum(fCount)",
  fcfa3c: "sum(f3CFAc)/sum(fCount)",
  fcfa4c: "sum(f4CFAc)/sum(fCount)",
  fcfa5c: "sum(f5CFAc)/sum(fCount)",
  fcfa6c: "sum(f6CFAc)/sum(fCount)",
  fcfa7c: "sum(f7CFAc)/sum(fCount)",
};

const DEFAULT_STATE = {
  selectedGroup: "province",
  filterData: [],
  data: [],
  filterApplied: false,
  filterChanged: false,
  subFilterDisplay: false,
  filterDimensionStatus: {
    ageFilter: false,
    incomeFilter: false,
    wealthFilter: false,
  },
  sort: {
    sortOn: "",
    sortBy: "Count",
    sortDir: "desc",
  },
  filterGroup: {
    sc: "WMdimPROVINCE",
    context: "0",
    geolevel: 1,
    provID: 0,
  },
  contextView: {},
  contextTotals: {
    households: 0,
    Wealth: 0,
    avgWealth: 0,
    cities: 0,
  },
  filterNationalTotals: {},

  filterDimension: {
    age1: 8,
    age2: 8,
    age3: 8,
    age4: 8,
    age5: 8,
    age6: 8,
    age7: 8,
    inc1: 8,
    inc2: 8,
    inc3: 8,
    inc4: 8,
    inc5: 8,
    inc6: 8,
    inc7: 8,
    wth1: 8,
    wth2: 8,
    wth3: 8,
    wth4: 8,
    wth5: 8,
    wth6: 8,
    wth7: 8,
  },
  filterRefinement: {
    L: 1,
    M: 1,
    H: 1,
    UH: 1,
    cmL: 0,
    cmM: 0,
    cmH: 0,
    cmUH: 0,
    dmL: 0,
    dmM: 0,
    dmH: 0,
    dmUH: 0,
    awmL: 0,
    awmM: 0,
    awmH: 0,
    awmUH: 0,
  },
  subFilter: {},
  // authenticationInfo:
  //   localStorage.getItem("wealthmineauinfo") != null
  //     ? JSON.parse(localStorage.getItem("wealthmineauinfo"))
  //     : {
  //         loggedIn: false,
  //         client: false,
  //         firstName: "",
  //         lastName: "",
  //         title: "",
  //         email: "",
  //         department: "",
  //         contactNum: "",
  //         uid: 0,
  //         role: "",
  //       },
  errorState: {
    show: false,
    errorCode: 0,
    message: "",
  },
  currentGranularityFilter: {},
  groups: {
    province: {
      sc: "WMdimPROVINCE",
      selected: false,
      valueField: "Name",
      geolevelIdFields: {},
      filterReset: true, // geolevel 1, sc = "0"
      drillDownEnabled: true,
      show: true,
      // Trial
      geolevel: 1,
      upi: 0,
      context: "0",
    },
    cma: {
      sc: "WMdimCMA",
      selected: false,
      valueField: "Name",
      nongroupField: "Non-CMA",
      geolevelIdFields: {
        2: "UPI",
      },
      filterReset: false,
      drillDownEnabled: true,
      show: true,
      // Trial
      geolevel: 2,
      upi: 0,
      context: "0",
    },
    city: {
      sc: "WMdimCITY",
      nongroupsc: "WMdimCITYnonCMA",
      selected: false,
      valueField: "City",
      geolevelIdFields: {
        2: "ProvID",
        3: "UPI",
      },
      filterReset: false,
      drillDownEnabled: true,
      show: true,
      // Trial
      geolevel: 3,
      upi: 0,
      context: "0",
    },
    fsa: {
      sc: "WMdimFSA",
      selected: false,
      valueField: "City",
      geolevelIdFields: {
        2: "ProvID",
        3: "prevData.UPI",
        4: "UPI",
      },
      filterReset: false,
      drillDownEnabled: true,
      show: true,
      // Trial
      geolevel: 4,
      upi: 0,
      context: "0",
    },
    pc: {
      sc: "WMdimPC",
      selected: false,
      valueField: "City",
      geolevelIdFields: {
        2: "ProvID",
        3: "prevData.UPI",
        4: "UPI",
      },
      filterReset: false,
      drillDownEnabled: false,
      show: false,
      upi: 0,
      context: "0",
      geolevel: 4,
    },
    age: {
      sc: "WMdimAGE",
      selected: false,
      valueField: "AGEgroup",
      filterReset: false,
      drillDownEnabled: false,
      show: true,
    },
    income: {
      sc: "WMdimINCOME",
      selected: false,
      valueField: "INCOMEgroup",
      filterReset: false,
      drillDownEnabled: false,
      show: true,
    },
    wealth: {
      sc: "WMdimWEALTH",
      selected: false,
      valueField: "CFAgroup",
      filterReset: false,
      drillDownEnabled: false,
      show: true,
    },
    userProfile: {},
  },
};

const StoreContext = createContext(_.cloneDeep(DEFAULT_STATE));

class StoreProvider extends Component {
  state = _.cloneDeep(DEFAULT_STATE);

  // setAuthenticationInfo = (user) => {
  //   var authenticationInfo = { ...this.state.authenticationInfo };
  //   authenticationInfo.loggedIn = true;
  //   authenticationInfo.client = user.client;
  //   authenticationInfo.firstName = user.firstName;
  //   authenticationInfo.lastName = user.lastName;
  //   authenticationInfo.title = user.title;
  //   authenticationInfo.email = user.email;
  //   authenticationInfo.department = user.department;
  //   authenticationInfo.contactNum = user.contactNum;
  //   authenticationInfo.uid = user.uid;
  //   authenticationInfo.role = user.role;
  //   this.setState({ authenticationInfo: authenticationInfo }, () => {
  //     localStorage.setItem(
  //       "wealthmineauinfo",
  //       JSON.stringify(authenticationInfo)
  //     );
  //   });
  // };

  // attemptLogout = () => {
    // var authenticationInfo = { ...this.state.authenticationInfo };
    // authenticationInfo.loggedIn = false;
    // authenticationInfo.client = false;
    // authenticationInfo.firstName = "";
    // authenticationInfo.lastName = "";
    // authenticationInfo.title = "";
    // authenticationInfo.email = "";
    // authenticationInfo.department = "";
    // authenticationInfo.contactNum = "";
    // authenticationInfo.uid = 0;
    // new HttpBaseService().GET("WMguardLogout.php");
    // this.setState(
    //   { ...DEFAULT_STATE, authenticationInfo: authenticationInfo },
    //   () => {
    //     localStorage.setItem(
    //       "wealthmineauinfo",
    //       JSON.stringify(authenticationInfo)
    //     );
    //   }
    // );
  // };

  getUrlParameters = () => {
    const { sortOn, ...sortOptions } = this.state.sort;
    return {
      ...sortOptions,
      ...this.state.filterGroup,
      ...this.state.filterDimension,
      ...this.state.filterRefinement,
      ...this.state.contextView,
    };
  };

  setFilterGroup = (filterGroup) => {
    this.setState({
      filterGroup: filterGroup,
    });
  };

  setContextTotals = (contextTotals) => {
    this.setState((state) => ({
      contextTotals: { ...state.contextTotals, ...contextTotals },
    }));
  };

  setContextTotalsFromData = (data) => {
    // Ye Kya kar raha hai
    if (
      this.state.filterGroup.context !== "0" ||
      ["cma", "city", "fsa", "age", "income", "wealth"].find(
        (d) => d === this.state.selectedGroup
      )
    ) {
      return;
    }
    let keys = {
      age: "age",
      income: "income",
      wealth: "wealth",
    };
    if (
      ["age", "income", "wealth"].find((d) => d === this.state.selectedGroup)
    ) {
      keys = {
        age: "ageOther",
        income: "incomeOther",
        wealth: "wealthOther",
      };
    }
    data = data.Field;
    let contextTotals = {};
    keyList[keys.age].map((index) => {
      contextTotals[index] = getSum(data, index);
    });
    keyList[keys.income].map((index) => {
      contextTotals[index] = getSum(data, index);
    });
    keyList[keys.wealth].map((index) => {
      contextTotals[index] = getSum(data, index);
    });
    let sumContextTotals = {
      sumAge: keyList[keys.age].reduce((a, b) => a + contextTotals[b], 0),
      sumInc: keyList[keys.income].reduce((a, b) => a + contextTotals[b], 0),
      sumWealth: keyList[keys.wealth].reduce((a, b) => a + contextTotals[b], 0),
    };

    this.setState((state) => ({
      contextTotals: {
        ...state.contextTotals,
        ...sumContextTotals,
        ...contextTotals,
      },
    }));
  };

  setSubFilterDisplay = (value) => {
    this.setState({
      subFilterDisplay: value,
    });
  };

  setSubFilter = (subFilterData) => {
    this.setState({
      subFilter: subFilterData,
    });
  };

  setUserProfile = (userProfile) => {
    this.setState({
      userProfile: userProfile,
    });
  };

  setFilterData = (filterDimensionData, filterRefinementData) => {
    let indexArray = [1, 2, 3, 4, 5, 6, 7];
    let filterApplied = false;
    let filterDimensionStatus = this.state.filterDimensionStatus;
    filterDimensionStatus.ageFilter = false;
    filterDimensionStatus.incomeFilter = false;
    filterDimensionStatus.wealthFilter = false;
    indexArray.forEach((i) => {
      filterDimensionStatus.ageFilter =
        filterDimensionData["age" + i] !== 8
          ? true
          : filterDimensionStatus.ageFilter;
      filterDimensionStatus.wealthFilter =
        filterDimensionData["wth" + i] !== 8
          ? true
          : filterDimensionStatus.wealthFilter;
      filterDimensionStatus.incomeFilter =
        filterDimensionData["inc" + i] !== 8
          ? true
          : filterDimensionStatus.incomeFilter;
    });
    if (
      Object.keys(filterDimensionStatus).some(
        (d) => filterDimensionStatus[d] === true
      )
    ) {
      filterApplied = true;
    }
    return new Promise((resolve) => {
      this.setState(
        {
          filterDimension: filterDimensionData,
          filterRefinement: filterRefinementData,
          filterApplied: filterApplied,
          filterChanged: true,
          filterDimensionStatus: filterDimensionStatus,
        },
        () => resolve()
      );
    });
  };

  setFilteredData = (data) => {
    this.setState({ filterData: data });
  };

  setData = (d) => {
    this.setState({ data: d, filterChanged: false });
  };

  setFilterNationalTotals = (d) => {
    let sum = {
      fSumInc: keyList.fincome.reduce((a, b) => a + parseFloat(d[b] || 0), 0),
      fSumAge: keyList.fage.reduce((a, b) => a + parseFloat(d[b] || 0), 0),
      fSumWealth: keyList.fwealth.reduce(
        (a, b) => a + parseFloat(d[b] || 0),
        0
      ),
    };
    this.setState((state) => ({
      contextTotals: { ...state.contextTotals, ...d, ...sum },
    }));
  };

  setSort = (sortOn) => {
    const toggle = (x) => (x === "asc" ? "desc" : "asc");
    let sort = this.state.sort;
    if (sort.sortBy === sortOptions[sortOn]) {
      sort.sortDir = toggle(sort.sortDir);
    } else {
      sort.sortBy = sortOptions[sortOn];
      sort.sortOn = sortOn;
      sort.sortDir = "desc";
    }
    return new Promise((resolve) => {
      this.setState({ sort: sort }, () => resolve());
    });
  };

  setSelectedFilterGroup = (group) => {
    this.setState({ selectedGroup: group });
  };

  setQuantilesData = (data) => {
    this.setState({ quantilesData: data });
  };

  setErrorState = (HttpCode, msg) => {
    this.setState((state) => ({
      ...state,
      errorState: {
        show: true,
        errorCode: HttpCode,
        message: msg,
      },
    }));
  };

  resetErrorState = () => {
    const { oktaAuth } = useOktaAuth();
    this.setState(
      (state) => ({
        ...state,
        errorState: {
          show: false,
          errorCode: 0,
          message: "",
        },
      }),
      async () => await oktaAuth.signOut() //this.attemptLogout() // 2024/05/31 - Okta update
    );
  };

  setGroups = (groups) => {
    this.setState({ groups: groups });
  };

  setGranularityFilter = (granularityFilter) => {
    this.setState({ currentGranularityFilter: granularityFilter });
  };

  render() {
    return (
      <StoreContext.Provider
        value={{
          ...this.state,
          // setAuthenticationInfo: this.setAuthenticationInfo,
          // attemptLogout: this.attemptLogout,
          getUrlParameters: this.getUrlParameters,
          setFilterGroup: this.setFilterGroup,
          setFilterData: this.setFilterData,
          setSubFilter: this.setSubFilter,
          setUserProfile: this.setUserProfile,
          setData: this.setData,
          setSubFilterDisplay: this.setSubFilterDisplay,
          setContextTotals: this.setContextTotals,
          setFilterNationalTotals: this.setFilterNationalTotals,
          setFilteredData: this.setFilteredData,
          setSort: this.setSort,
          setContextTotalsFromData: this.setContextTotalsFromData,
          setSelectedFilterGroup: this.setSelectedFilterGroup,
          setQuantilesData: this.setQuantilesData,
          setErrorState: this.setErrorState,
          resetErrorState: this.resetErrorState,
          setGroups: this.setGroups,
          setGranularityFilter: this.setGranularityFilter,
          setAuthToken: this.setAuthToken,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}

export { StoreContext, StoreProvider };

